.wrapper {
  height: 75vh;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px #000;
}

.text-block {
  margin-right: 25px;
  padding: 7px;
  font-family: Roboto, sans-serif;
}

.header {
  height: 5%;
  border-bottom: 1px solid #b1adad;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12%;
  display: flex;
}

.body {
  height: 100%;
  border-bottom: 1px #3f904f;
}

.wrapperconversaion {
  height: 85%;
  padding-top: 10px;
  overflow: auto;
}

.wrappercomentario {
  padding: 10px 10px 10px 12%;
}

.comentario {
  width: 88%;
  min-height: 20px;
  background-color: rgba(174, 214, 228, .35);
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  display: flex;
}

.comentario.respuesta {
  width: 88%;
  background-color: rgba(227, 231, 231, .35);
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.text-block-2 {
  text-align: right;
  border: 1px #c2c0c0;
  border-radius: 6px;
  padding: 3px 6px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  display: inline-block;
}

.paragraph {
  margin-top: 16px;
  font-family: Roboto, sans-serif;
  align-self: flex-start;
}

.paragraphrespuesta {
  margin-top: 16px;
  font-family: Roboto, sans-serif;
  align-self: flex-end;
}

.wrapperrespuesta {
  justify-content: flex-end;
  padding: 10px 12% 10px 10px;
  display: flex;
}

.text-block-3 {
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.text-block-3.bold {
  margin-left: 8px;
  font-weight: 700;
}

.footer {
  height: 23%;
  border-top: 1px solid #cfcece;
  justify-content: space-between;
  padding: 0 12%;
  display: flex;
}

.contestacion {
  width: 100%;
  height: 87%;
  background-color: rgba(243, 242, 242, 0);
  border: 1px solid #e0dede;
  border-radius: 6px;
  overflow: auto;
}

.text-block-4 {
  height: 10%;
  margin-bottom: 8px;
  font-family: Roboto, sans-serif;
}

.wrappercontesta {
  width: 80%;
  height: 96%;
  padding: 10px 10px 10px 0%;
}

.div-block {
  width: 19%;
  height: 100%;
  background-color: rgba(247, 247, 247, 0);
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0%;
  padding: 15px 10px;
  display: flex;
  position: relative;
}

.div-block-2 {
  height: 10%;
}

.button {
  text-align: center;
  border-radius: 6px;
}

.div-block-3 {
  height: auto;
  min-height: 20px;
  border: 1px solid #c9c7c7;
  border-radius: 6px;
}

.div-block-4 {
  width: 50%;
  justify-content: flex-start;
  padding-left: 0%;
  display: flex;
}

.div-block-5 {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}

.div-block-6 {
  border-top: 1px solid #c2c0c0;
  justify-content: flex-end;
  padding-top: 4px;
  display: flex;
}
