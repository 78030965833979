:root {
  --cover-border: 3px rgb(55, 211, 133) dashed;
  --tag-options-color: brown
}

.directionButton {
  display: none;
  margin-left: 15px;
}

.actionButtons {
  display: none
}

.blockContainerInitial {
  opacity: 0;
}

.blockContainer {
  transition: opacity 0.5s;
  opacity: 1;
}

.blockContainer:hover .directionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blockContainer:hover .actionButtons {
  display: block
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: var(--cover-border);
  width: '100%';
  margin: auto;
  color: gray;
}

.selectBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: blue;
  color: aliceblue;
}

div.DraftEditor-root {
  background-color: transparent;
  width: 100%;
  margin: auto;
  /* box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5); */
  height: auto;
  padding: 20px;
  font-size: 18px;
  font-family: 'calibri', sans-serif;
  color: inherit;
}

.rdw-dropdown-optionwrapper {
  color: black
}

.rdw-dropdown-selectedtext {
  color: black;
}

ul.css-vzwa95 {
  position: absolute;
  z-index: 1000;
  background-color: var(--tag-options-color);
}

.toolbarClassName {
  z-index: 1000;
  top: 0;
  width: 220mm;
}

.textContainer {
  border-radius: 10px;
  margin-top: 8px;
  width: 220mm;
  background-color: transparent;
  min-height: 120px;
}

.rdw-colorpicker-modal-header {
  color: black;
  width: 100%;
}

div .rdw-colorpicker-modal {
  width: 250px;
}

.buttonText {
  display: none;
}

@media (min-width: 1600px) {
  .toolbarClassName {
    width: 300mm;
  }

  .textContainer {
    width: 300mm;
  }

  .buttonText {
    display: flex;
    margin-left: 8px;
  }
}
